import React from "react";
import Helmet from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/layout";
import { Breadcrumbs } from "../components/styleguide";

const StaticPage = ({ pageContext }) => {
  const { data, common, breadcrumbs, enableCookie } =
    pageContext;
  const is_cookie_page =
    data.slug === "cookie-policy" ||
    data.slug === "polityka-cookie";
  const is_terms_page =
    data.slug === "ogolne-warunki-sprzedazy" ||
    data.slug === "terms-of-sale";
  return (
    <Layout
      data={common}
      title={data.seoTitle}
      description={data.seoDescription}
      enableCookie={enableCookie}
      hrefLang={data.hrefLang}>
      <div className="static-page-content">
        <div className="main-container">
          <Breadcrumbs
            data={breadcrumbs}
            lang={common.lang}
          />
          <header>
            <h1>{data.title}</h1>
          </header>
          <div className="markdown-content">
            <div id="pdf_content">
              <div className="static-site-table">
                {" "}
                {ReactHtmlParser(data.content_html)}
              </div>
              {is_cookie_page && <div id="bodycontent" />}
            </div>
            {data.pdf_download && (
              <a
                href={data.pdf_download}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-md fill">
                {common.translations[0].download}
              </a>
            )}
            {(is_cookie_page || is_terms_page) && (
              <>
                <Helmet>
                  {[
                    "terms-of-sale",
                    "thank-you",
                    "polityka-prywatnosci",
                  ].includes(data.slug) ? (
                    <meta
                      name="robots"
                      content="noindex, follow"
                    />
                  ) : null}
                  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.1/html2pdf.bundle.min.js" />
                  <script>
                    {`     window.generatePDF = () => {
                        html2pdf(document.getElementById("pdf_content"), {margin: 5,filename: '${data.slug}.pdf'})
                      }`}
                  </script>
                </Helmet>
                <a
                  className="btn btn-md fill"
                  style={{ marginTop: 20 }}
                  onClick={() => window.generatePDF()}>
                  {common.translations[0].download} PDF
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StaticPage;
